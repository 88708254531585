import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAvatar,
  VBottomNavigation,
  VBottomSheet,
  VBtn,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCarousel,
  VCarouselItem,
  VCheckbox,
  VCol,
  VContainer,
  VDataIterator,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFileInput,
  VForm,
  VIcon,
  VImg,
  VList,
  VListItem,
  VListItemTitle,
  VMain,
  VMenu,
  VProgressCircular,
  VProgressLinear,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSheet,
  VSnackbar,
  VSpacer,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSwitch,
  VTextarea,
  VTextField,
  VTooltip,
} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";

import body1 from "../components/Text/Body1.vue";
import body2 from "../components/Text/Body2.vue";
import body3 from "../components/Text/Body3.vue";
import herotext from "../components/Text/HeroText.vue";
import subtitle1 from "../components/Text/Subtitle1.vue";
import subtitle2 from "../components/Text/Subtitle2.vue";

Vue.use(Vuetify, {
  components: {
    VCard,
    VApp,
    VSpacer,
    VAppBar,
    VIcon,
    VBtn,
    VMenu,
    VAlert,
    VSnackbar,
    VDialog,
    VBottomSheet,
    VBottomNavigation,
    VMain,
    VCol,
    VRow,
    VTextField,
    VList,
    VListItem,
    VListItemTitle,
    VCardSubtitle,
    VCardTitle,
    VCardText,
    VCardActions,
    VCheckbox,
    VSelect,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VForm,
    VProgressCircular,
    VContainer,
    VDatePicker,
    VProgressLinear,
    VImg,
    VTextarea,
    VFileInput,
    VCarousel,
    VCarouselItem,
    VSheet,
    VDataIterator,
    VDataTable,
    VAvatar,
    VTooltip,
    VStepper,
    VStepperStep,
    VStepperContent,
    VStepperHeader,
    VStepperItems,
    VDivider,
    VSwitch,
    VRadio,
    VRadioGroup,
    herotext,
    subtitle1,
    subtitle2,
    body1,
    body2,
    body3,
  },
  directives: {
    Ripple,
  },
});

const opts = {
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#333333",
        error: "#C42010",
      },
      dark: {
        background: "#0A201D",
      },
    },
  },
};

export default new Vuetify(opts);
