import axios from "axios";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

// Redirect user to Spotify login
const connectToSpotify = ({ userId, bandId, redirectTo }) => {
  const conditionalString = bandId ? `bandId=${bandId}` : `userId=${userId}`;
  window.location.replace(
    `${baseUrl}/api/spotify/data?${conditionalString}&redirectTo=${encodeURIComponent(
      redirectTo
    )}`
  );
};

// Fetch Spotify user data after login
const getSpotifyUserData = async ({ userId, bandId }) => {
  try {
    const conditionalString = bandId ? `bandId=${bandId}` : `userId=${userId}`;
    const urlToQuery = `${baseUrl}/api/spotify/data?${conditionalString}`;
    const axiosResponse = await axios.get(urlToQuery);
    return axiosResponse.data;
  } catch (err) {
    console.error("Error fetching Spotify user data:", err);
    return false;
  }
};

const getTopArtists = async ({ userId, bandId, redirectTo }) => {
  try {
    const conditionalString = bandId ? `bandId=${bandId}` : `userId=${userId}`;
    const urlToQuery = `${baseUrl}/api/spotify/top-artists?${conditionalString}&redirectTo=${encodeURIComponent(
      redirectTo
    )}`;
    const axiosResponse = await axios.get(urlToQuery);
    // The array of top artists is in the items key of the response
    return axiosResponse.data.items;
  } catch (err) {
    console.error("Error fetching Spotify top artists:", err);
    return false;
  }
};

const getTopTracks = async ({ userId, bandId, redirectTo }) => {
  try {
    const conditionalString = bandId ? `bandId=${bandId}` : `userId=${userId}`;
    const urlToQuery = `${baseUrl}/api/spotify/top-songs?${conditionalString}&redirectTo=${encodeURIComponent(
      redirectTo
    )}`;
    const axiosResponse = await axios.get(urlToQuery);
    // The array of top songs is in the items key of the response
    return axiosResponse.data.items;
  } catch (err) {
    console.error("Error fetching Spotify top songs:", err);
    return false;
  }
};

const searchSpotify = async ({ userId, searchQuery, type = "artist" }) => {
  try {
    const redirectTo = window.location.pathname; // Capture current page URL
    const response = await axios.get(
      `${baseUrl}/api/spotify/search?userId=${userId}&searchQuery=${searchQuery}&type=${type}&redirectTo=${encodeURIComponent(
        redirectTo
      )}`
    );
    return response.data;
  } catch (error) {
    console.error("Error searching Spotify:", error);
    return [];
  }
};

const doesSpotifyTokenExist = async ({ userId, bandId }) => {
  // Response data format: { entryExists: true/false, tokenExists: true/false, spotifyIdExists: true/false }
  try {
    const conditionalString = bandId ? `bandId=${bandId}` : `userId=${userId}`;
    const urlToQuery = `${baseUrl}/spotify/access-token-exists?${conditionalString}`;
    const axiosResponse = await axios.get(urlToQuery);
    return axiosResponse.data;
  } catch (err) {
    console.error("Error fetching Spotify token:", err);
    return false;
  }
};

const areArtistsOnJuke = async ({ userId, arrayOfSpotifyIds }) => {
  try {
    const idsString = arrayOfSpotifyIds.join(","); // Convert array to comma-separated string
    const urlToQuery = `${baseUrl}/spotify/are-artists-on-juke?userId=${userId}&artistSpotifyIds=${idsString}`;
    const axiosResponse = await axios.get(urlToQuery);
    return axiosResponse.data;
  } catch (err) {
    console.error("Error in areArtistsOnJuke:", err);
    return false;
  }
};

export {
  connectToSpotify,
  getSpotifyUserData,
  getTopArtists,
  getTopTracks,
  searchSpotify,
  doesSpotifyTokenExist,
  areArtistsOnJuke,
};
